import React, { useState, ChangeEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

const App: React.FC = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(event.target.value);
    setOutputText(formatInputText(event.target.value));
  };

  const formatInputText = (text: string): string => {
  const regex = /([\wäöüÄÖÜß]+),\s+([\wäöüÄÖÜß]+)\s+\(([\wäöüÄÖÜß]+)\)\s*(=\s*([\wäöüÄÖÜß]+))?/gu;
  const matches = Array.from(text.matchAll(regex));
  let formattedText = '';
  for (const match of matches) {
    const [, lastName, firstName, instrument, , role] = match;
    const fullName = `${firstName} ${lastName}`;
    formattedText += `${fullName}, ${instrument}${role ? ` = ${role}` : ''}; `;
  }
  return formattedText.trim();
};

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-6">
          <div className="mb-3">
            <label htmlFor="inputTextArea" className="form-label">Nachname1, Vorname1 (Instrument1) = Rolle;</label>
            <textarea className="form-control" id="inputTextArea" rows={5} value={inputText} onChange={handleInputChange}></textarea>
          </div>
        </div>
        <div className="col-6">
          <div className="mb-3">
            <label htmlFor="outputTextArea" className="form-label">Vorname1 Nachname1, Instrument1 = Rolle;</label>
            <textarea className="form-control" id="outputTextArea" rows={5} value={outputText} readOnly></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;